import axios from 'axios';
import authHeader from '../services/auth-header';
import qs from 'qs';
import store from "@/store/store";
import {vm} from '@/main';

const instance = axios.create({
    baseURL: process.env.VUE_APP_AXIOS_BASE_URL.replace('localhost', window.location.hostname),
    timeout: 15000,
    paramsSerializer: params => qs.stringify(params, {
        arrayFormat: 'repeat'
    }),
    headers: authHeader()
});

let versionTimestamp = null;

instance.interceptors.response.use(
    response => {

        const currentTimestamp = response.headers['version-timestamp'];
        if (currentTimestamp) {
            if (versionTimestamp && versionTimestamp != currentTimestamp) {
                vm.$bvModal.msgBoxOk(
                    "For at fortsætte, skal du klikke OK for at genindlæse den nye version og komme videre",
                    {
                        title: "Systemet er opdateret siden du sidst har været på!"
                    }).then(value => {
                    if (value) {
                        window.location.reload();
                    }
                })
            }
            versionTimestamp = currentTimestamp;
        }

        return response;
    }, function (error) {
        console.log("error " + error)
        if (error.response.status === 401) {
            store.dispatch('auth/logout');
            window.location = '/login';
        }
        return Promise.reject(error)
    }
);

export default instance;
