import axios from '@/config/axios';

export const authenticationService = {

    login(loginRequest) {
        return axios.post(`auth/signin`, loginRequest)
    },

    recoverPassword(email) {
        return axios.post(`auth/password/recover`, {username: email})
    },

    createPassword(token, password) {
        return axios.post(`auth/password/create`, {token: token, password: password})
    },

    changePassword(oldpassword, password) {
        return axios.post(`auth/password/change`, {oldpassword: oldpassword, password: password})
    },

    logout() {
        localStorage.removeItem('user');
    }

}
