import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store/store';

import '@babel/polyfill'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import '@/config/bootstrap-vue'
import '@/config/ckeditor5'
import '@/config/vue2-datepicker';
import '@/config/style.scss';
import '@/config/vuelidate';
import '@/config/typeahead';
import '@/config/vuemeta';
import {i18n} from '@/config/i18n/i18n';

Vue.config.productionTip = false

export const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

Vue.directive("disable-all", {
  // When all the children of the parent component have been updated
  componentUpdated: function(el, binding) {
    if (!binding.value) return;
    const tags = ["input", "button", "textarea", "select"];
    tags.forEach(tagName => {
      const nodes = el.getElementsByTagName(tagName);
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].disabled = true;
        nodes[i].tabIndex = -1;
      }
    });
  }
});