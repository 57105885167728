<template>
  <b-modal
      v-model="open"
      size="xl"
      body-class="position-static"
      title="Seneste opdateringer af systemet"
  >

    <div v-html="systemNews.message"/>

    <template #modal-footer>
      <b-button v-b-modal.modal-close_visit @click="close" variant="outline-secondary" class="btn-sm">Luk</b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  data(){
    return {
      open: false,
      systemNews: {
        massage: ""
      }
    }
  },
  components: {
  },
  methods: {
    close() {
      this.open = false;
    },
    show(systemNews) {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.systemNews = systemNews;
      this.open = true;
    }
  }
}

</script>