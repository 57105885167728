<template>
  <b-navbar toggleable="lg" type="light" variant="secondary" fixed="top">
    <b-navbar-brand href="/" class="ml-4">
      <b-img src="@/assets/boligsiden_home.svg" fluid></b-img>
    </b-navbar-brand>
    <b-navbar-brand>
      <span style="font-family: Juli sans Bold, Times, serif; font-size: small" class="p-1">TVANGSAUKTIONER</span>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-navbar-nav class="ml-4 mt-1">
      <b-nav-item href="/"><i class="fa fa-question-circle" aria-hidden="true"></i></b-nav-item>
      <b-nav-item href="/user-guide" class="d-none d-xl-block">Guide</b-nav-item>
    </b-navbar-nav>

    <b-navbar-nav class="ml-4 mt-1">
      <b-nav-item href="/"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></b-nav-item>
      <b-nav-item @click="systemNewsShow" class="d-none d-xl-block">System opdateret {{ offsetDatetimeToMoment(systemNews.updatedDateTime).format('DD-MM-YYYY HH:mm') }}</b-nav-item>
    </b-navbar-nav>

    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">

        <b-nav-item-dropdown right v-if="currentUser">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>{{ currentUser.username }}</em>
          </template>
          <b-dropdown-item href="#">
            <a class="nav-link" href @click.prevent="changePassword">
              Skift din adgangskode
            </a>
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <a class="nav-link" href @click.prevent="logOut">
              Log ud
            </a>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item right v-if="!currentUser">
          <router-link to="/login" class="nav-link">
            Log ind
          </router-link>
        </b-nav-item>

      </b-navbar-nav>
    </b-collapse>
    <system-news-show ref="system-news-show"/>
  </b-navbar>
</template>

<script>

import {systemAdminService} from "@/services/system-admin";
import SystemNewsShow from "@/components/admin/SystemNewsShow.vue";
import {systemNewsService} from "@/services/system-news";
import utils from "@/utils/utils";

export default {
  data() {
    return {
      systemNews: {
        updatedDateTime: null
      }
    }
  },
  components: {
    SystemNewsShow
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logOut() {
      if (this.currentUser) {
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      }
    },
    changePassword() {
      this.$router.push('/change-password');
    },
    changeBack() {
      systemAdminService.changeBack().then().catch(e => {
        this.errors.push(e)
      });
    },
    systemNewsShow() {
      this.$refs['system-news-show'].show(this.systemNews);
    },
    offsetDatetimeToMoment: utils.offsetDatetimeToMoment,
  },
  mounted() {
    systemNewsService.get().then(result => {
      this.systemNews = result.data;
    }).catch(e => {
      this.errors.push(e)
    });
  }
}
</script>

<style lang="scss" scoped>
@import '~@/config/bootstrap-vue-variables';

// hack to get sticky to work properly on our 100% height html/body pages
.navbar-wrapper {
  min-height: $navbar-height;
}

.navbar {
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.125);
  background-color: #f2f5f5 !important;
  padding-left: 0;
  height: $navbar-height;

  &, .navbar-text {
    color: black;
  }
}

.nav-link {
  &, i {
    color: white;
  }
}
</style>

