import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    da: require('./languages/da.json'),
    en: require('./languages/en.json')
};
const locale = 'da';

export const i18n = new VueI18n({
    locale,
    fallbackLocale: locale,
    messages
});

