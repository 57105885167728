<template>
  <b-container>
    <nav-bar-admin v-if="isAdmin"/>
    <nav-bar-mortgage v-if="isMortgage"/>
    <nav-bar-lawyer v-if="isLawyer"/>
  </b-container>
</template>

<script>

import {systemAdminService} from "@/services/system-admin";
import NavBarAdmin from "@/components/navbar/NavBarAdmin";
import NavBarMortgage from "@/components/navbar/NavBarMortgage";
import NavBarLawyer from "@/components/navbar/NavBarLawyer";

export default {
  components: {
    NavBarAdmin,
    NavBarMortgage,
    NavBarLawyer
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ADMIN');
      }

      return false;
    },
    isLawyer() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('LAWYER');
      }

      return false;
    },
    isMortgage() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('MORTGAGE');
      }

      return false;
    },
  },
  methods: {
    logOut() {
      if (this.currentUser) {
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      }
    },
    changePassword() {
      this.$router.push('/change-password');
    },
    changeBack() {
      systemAdminService.changeBack().then().catch(e => {
        this.errors.push(e)
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/config/bootstrap-vue-variables';

// hack to get sticky to work properly on our 100% height html/body pages
.navbar-wrapper {
  min-height: $navbar-height;
}

.navbar {
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.125);
  background-color: #f2f5f5 !important;
  padding-left: 0;
  height: $navbar-height;

  &, .navbar-text {
    color: black;
  }
}

.nav-link {
  &, i {
    color: white;
  }
}
</style>

