import axios from '@/config/axios';

export const systemNewsService = {

    get() {
        return axios.get(`system-news`);
    },
    set(systemNews) {
        return axios.post(`system-news`, systemNews);
    },
    setUserRead(systemNewsId) {
        return axios.get(`system-news/read/${systemNewsId}`);
    }
}
