import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/health',
            component: () => import('./views/HealthCheck.vue')
        },
        {
            path: '/home',
            component: Home
        },
        {
            path: '/login',
            component: () => import('./views/LoginForm.vue')
        },
        {
            path: '/new-password',
            props: route => ({token: route.query.token}),
            component: () => import('./views/CreatePassword.vue')
        },
        {
            path: '/change-password',
            component: () => import('./views/ChangePassword.vue')
        },
        {
            path: '/user-settings',
            component: () => import('./views/UserSettings.vue')
        },
        {
            path: '/recover',
            component: () => import('./views/RecoverPassword.vue')
        },
        {
            path: '/user-guide',
            name: 'userGuide',
            component: () => import('./views/UserGuide.vue')
        },
        {
            path: '/admin',
            name: 'admin',
            component: () => import('./views/AdminPage.vue')
        },
        {
            path: '/company/admin',
            name: 'companyadmin',
            component: () => import('./views/CompanyAdminPage.vue')
        },
        {
            path: '/lawyer',
            name: 'lawyer',
            component: () => import('./views/LawyerPage.vue')
        },
        {
            path: '/lawyer/case/:id',
            name: 'case',
            component: () => import('./views/case/CaseDashboard')
        },
        {
            path: '/lawyer/case/light/:id',
            name: 'case-light',
            component: () => import('./views/case/light/CaseDashboard')
        },
        {
            path: '/mortgage',
            name: 'mortgage',
            component: () => import('./views/MortgagePage.vue')
        },
        {
            path: '/lawyer/create-case',
            name: 'createCase',
            component: () => import('./views/case/new/CreateCase')
        },
        {
            path: '/mortgage-request',
            name: 'mortgageRequest',
            props: route => ({token: route.query.token}),
            component: () => import('./views/public/MortgageRequest')
        },
        {
            path: '/cancel-signup',
            name: 'cancelSignup',
            props: route => ({token: route.query.token}),
            component: () => import('./views/public/ViewingSignupCancel')
        },
        {
            path: '/unsubscribe',
            name: 'unsubscribe',
            props: route => ({token: route.query.token}),
            component: () => import('./views/public/Unsubscribe')
        },
        {
            path: '/debt-collection/create-case',
            name: 'createDebtCollection',
            component: () => import('./views/debtcollection/new/CreateCase')
        },
        {
            path: '/debt-collection/edit-case/:id',
            name: 'editDebtCollection',
            component: () => import('./views/debtcollection/DebtCollectionMortgageEdit')
        },
        {
            path: '/lawyer/debt-collection/edit-case/:id',
            name: 'editDebtCollectionLawyer',
            component: () => import('./views/debtcollection/DebtCollectionLawyerEdit')
        },
    ]
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/recover', '/home', '/health', '/new-password', '/mortgage-request', '/cancel-signup', '/unsubscribe'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});
