import Vue from 'vue';
import Vuex from 'vuex';
import {i18n} from '@/config/i18n/i18n';

import { auth } from './auth.module';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        language: 'da',
    },
    modules: {
        auth
    },
    mutations: {
        setLanguage(state, language) {
            state.language = language;
            i18n.locale = language;
        },
    },
    actions: {
    }
});