<template>
  <div id="app">
    <NavBar></NavBar>
    <router-view />
  </div>
</template>

<script>

import NavBar from '@/components/navbar/NavBar';

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
#app {
  margin-top: 80px;
  height: 100%;
}
</style>
