import moment from "moment-timezone";

const utils = {

    toVuelidateState(value) {
        return value?.$dirty ? !value.$error : null;
    },
    offsetDatetimeToMoment(datetime) {
        return moment.unix(datetime)
    },
    localDatetimeToMoment(datetime) {
        return moment.utc(datetime, 'YYYY-MM-DD HH:mm')
    },
    toFormData(parts) {
        const formData = new FormData();
        parts.forEach(part => formData.append(part.name, utils.toFormDataPart(part.type, part.data)));
        return formData;
    },
    toFormDataPart(type, data) {
        switch (type) {
            case 'json':
                return new Blob([JSON.stringify(data)], {type: 'application/json'});
            case 'text':
                return new Blob([data], {type: 'text/plain'});
            default:
                return data;
        }
    },


}

export default utils;