import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/config/bootstrap-vue.scss';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

